import Vue from "vue";
import "vue-googlemaps/dist/vue-googlemaps.css";
import VueGoogleMaps from "vue-googlemaps";

Vue.use(VueGoogleMaps, {
  load: {
    // Google API key
    apiKey: "AIzaSyBcCSdeheUixtfG1l4ga-TasZslQuPx4uU",
    // Enable more Google Maps libraries here
    libraries: ["places"],
    // Use new renderer
    useBetaRenderer: false,
  },
});
