<template>
  <div class="home-page">
    <div class="home-page__map">
      <iframe
        :src="iframeSrc"
        :style="iframe.style"
        :height="iframe.style.height"
        :width="iframe.style.width"
        frameborder="0"
        allowfullscreen
        ref="iframeMap"
      ></iframe>
    </div>
    <div class="home-page__getLocation">
      <phone-location
        @getLocation="getLocation"
        @getStart="getStart"
        @setMapCenterByCoordinates="setMapCenterByCoordinates"
      ></phone-location>
    </div>
  </div>
</template>
<script>
import "@/plugins/googleMaps";
import PhoneLocation from "./components/getLocation";
import { mapActions, mapState } from "vuex";
import { getCoordinatesByIp } from "@/service/home";
import { API_KEY } from "@/common/config";

export default {
  components: { PhoneLocation },
  data() {
    return {
      mode: 'view',
      center: '22.316668, 114.183334',
      zoom: 3,
      $_map: null,
      cacheCenter: null,
      iframe: {
        style: {
          border: 0,
          width: '100%',
          height: '100%',
        },
      }
    };
  },
  created() {
    this.getCountry();
    this.getCurrentCoordinates();
  },
  computed: {
    ...mapState(["currentCoordinates"]),
    iframeSrc: {
      get: function(){
        return `//www.google.com/maps/embed/v1/${this.mode}?key=${API_KEY}&center=${this.center}&zoom=${this.zoom}`;
      }
    },
  },
  watch: {
    currentCoordinates: {
      handler(nv) {
        JSON.stringify(nv) !== "{}" && this.changeCountry(nv);
      },
      deep: true,
    },
  },
  mounted() {
    this.setInitialMapData();
  },
  methods: {
    ...mapActions(["getCountry", "getCurrentCoordinates"]),
    setInitialMapData() {
      this.$_map = this.$refs?.iframeMap;
      this.zoom = 3;
      this.getCoordinatesByIp();
      if (this.cacheCenter) {
        const { lat, lng } = this.cacheCenter;
        this.center = `${lat}, ${lng}`;
        this.cacheCenter = null;
      }
    },
    changeCountry({ lat, lng }) {
      if (!this.$_map) {
        this.cacheCenter = { lat, lng };
      } else {
        this.center = `${lat}, ${lng}`;
      }
    },
    async getCoordinatesByIp() {
      const { data } = await getCoordinatesByIp();
      const { lat = 38.903085, lng = -77.03758 } = data || {};
      this.center = `${lat}, ${lng}`;
    },
    setMapCenterByCoordinates() {
      const currentCenter = this.center;
      this.center = currentCenter;
      this.zoom = 15;
    },
    getStart() {
      this.zoom = 5;
    },
    getLocation() {
      this.zoom = 15;
    },
  },
};
</script>
<style lang="scss" scoped>
.home-page,
.home-page__map,
.map {
  height: 100%;
}
</style>
