<template>
  <div class="start-location">
    <v-card-text class="d-flex flex-column justify-center align-center pb-0">
      <p :class="`${xs ? 'text-h5' : 'text-h4'} mt-4 t-primary font-weight-medium`">
        {{ $t("lang.bar.title") }}
      </p>
      <!-- <p :class="`${xs ? 'caption' : 'subtitle-1'} text-no-wrap`">
        <v-icon dark :size="18" :color="themeColor">mdi-check-bold</v-icon>
        <span class="font-weight-medium" :style="{ color: '#1C1C36' }">
          {{ $t("lang.home.agree") }}
        </span>
      </p> -->
      <v-carousel
        cycle
        :hide-delimiter-background="true"
        :hide-delimiters="true"
        :show-arrows="false"
        :touchless="true"
        :interval="2500"
        height="auto"
      >
        <v-carousel-item v-for="(item, key) in carouselsList" :key="key">
          <p :class="`${xs ? 'caption' : 'subtitle-1'} text-no-wrap text-center`">
            <v-icon dark :size="18" :color="themeColor">mdi-check-bold</v-icon>
            <span class="font-weight-medium" :style="{ color: '#1C1C36' }">
              {{ $t(item) }}
            </span>
          </p>
        </v-carousel-item>
      </v-carousel>
    </v-card-text>
    <v-card-text>
      <div :class="`${xs ? 'caption' : 'body-2'} text-no-wrap mb-2`" :style="{ color: '#1C1C36' }">
        {{ $t("lang.home.phoneNumber.tips") }}
      </div>
      <country-number
        v-model="phoneNumber"
        @country="handleCountryChange"
        ref="myCountryNumber"
      ></country-number>
      <v-btn
        block
        x-large
        dark
        color="#6C5BE7"
        depressed
        :loading="startLoading"
        :rounded="true"
        :class="`${$vuetify.breakpoint.xs ? 'mt-3' : 'mt-5'} text-transform-none`"
        @click="handleStart"
      >
        {{ $t("lang.home.start") }}
      </v-btn>
      <div :class="`${$vuetify.breakpoint.xs ? 'mt-2' : 'mt-3'}`">
        <small :style="{ color: '#AAAAB1' }">{{ $t("lang.home.subTitle") }}</small>
      </div>
    </v-card-text>
    <v-snackbar v-model="openToast" top :timeout="3000">
      {{ $t("lang.share.refuse.attention.requriedPhoneNumber") }}
    </v-snackbar>
  </div>
</template>
<script>
import { themeColor } from "@/common/const";
import CountryNumber from "@/components/countryNumber/country";
/**
 *  国际化
 *
 *  lang.home.tips.anyPhone  Locate any mobile phone
 *  lang.home.tips.noAppsOrDownloads  No apps or downloads
 *  lang.home.tips.AccurateInFeet  Accurate to within 50 feet*
 */
export default {
  components: { CountryNumber },
  props: {
    startLoading: Boolean,
  },
  data() {
    return {
      phoneNumber: "",
      openToast: false,
      themeColor,
      carouselsList: [
        "lang.home.tips.anyPhone",
        "lang.home.tips.noAppsOrDownloads",
        "lang.home.tips.AccurateInFeet",
        "lang.home.agree",
      ],
    };
  },
  computed: {
    xs() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    handleCountryChange(country) {
      // todo： 跳转到对应国家，经纬度；
      this.$emit("country", country);
    },
    changeCountryCode() {
      // todo 增加功能；
      this.$refs.myCountryNumber.changeSelectedItem();
    },
    handleStart() {
      const result = this.$refs.myCountryNumber.getData();
      if (!result.phoneNumber) {
        this.openToast = true;
        return;
      }
      this.$emit("lookForLocation", result);
    },
  },
};
</script>
