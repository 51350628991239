<template>
  <v-dialog
    transition="dialog-top-transition"
    :value="true"
    max-width="584px"
    persistent
    no-click-animation
  >
    <v-fade-transition>
      <v-card rounded="xl" elevation="1" v-show="cardShowFlag">
        <v-toolbar dense :color="themeColor" light flat rounded="24px">
          <div class="loading" v-if="showSearching"></div>
        </v-toolbar>
        <div class="content">
          <start-location
            v-if="flag === 'country'"
            :startLoading="showSearching"
            @lookForLocation="handleLookForLocation"
            v-on="$listeners"
          ></start-location>
          <look-for
            v-else
            @getLocation="handleGetLocation"
            :submitLoading="loading"
            @changeLocationByIp="changeLocationByIp"
            @cancelChangeLocationByIp="cardShowFlag = true"
          ></look-for>
        </div>
      </v-card>
    </v-fade-transition>
    <!-- 支付 -->
    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="publishableKey"
      :sessionId="sessionId"
      :line-items="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      @loading="(v) => (loading = v)"
    />
  </v-dialog>
</template>
<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { themeColor } from "@/common/const";
import { createOrder, checkoutSession } from "@/service/home";
// import { formSerialize } from "@/utils/utils";
import StartLocation from "./startLocation";
import LookFor from "./lookForLocation";

export default {
  components: { StartLocation, LookFor, StripeCheckout },
  data() {
    return {
      themeColor,
      countryInfo: {},
      flag: "country",
      trackOrderId: "",
      serialId: "", // 需要传递给后台
      publishableKey:
        "pk_live_51InP56DbJX4FKyISUqM2Wc1cJ0lph6HLxpr2Ef1CnMeq2aX2LOv14e7LorJEYTcrAbFCmMvM54Wk6JEiMsW8k3vN00YfQ0jNQq",
      lineItems: [],
      successURL: window.location.href,
      cancelURL: window.location.href,
      sessionId: "",
      loading: false,
      showSearching: false,
      cardShowFlag: true,
    };
  },
  computed: {
    xs() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    gtag(action, event_category, event_label) {
      window.gtag && window.gtag("event", action, { event_category, event_label, event_value: 0 });
    },
    changeLocationByIp() {
      console.log(`执行了！！！`);
      this.cardShowFlag = false;
      this.showSearching = false;
      this.$emit("setMapCenterByCoordinates");
    },
    async handleLookForLocation(result) {
      this.countryInfo = result;
      this.showSearching = true;
      try {
        const { code, data } = await createOrder({
          smsCode: result.smsCode,
          trackMobile: result.phoneNumber,
        });
        if (Number(code)) {
          this.showSearching = false;
          return;
        }
        this.trackOrderId = data.id;
        this.serialId = data.serialId;
        setTimeout(() => {
          // this.showSearching = false;
          this.flag = "lookFor";
          this.$emit("getStart");
        }, 1000);
      } catch (e) {
        this.showSearching = false;
      }
    },
    async handleGetLocation() {
      const that = this;
      this.loading = true;
      this.gtag("pay_click", "pay", "点击付费(Get Location) 跳转striple页面");
      const { code, data } = await checkoutSession({ trackOrderId: that.trackOrderId });
      if (Number(code)) return;
      const { priceId, sessionId } = data;
      this.sessionId = sessionId;
      this.lineItems.push({ price: priceId, quantity: 1 });
      this.$refs.checkoutRef.redirectToCheckout();
      // that.gtag("purchase_success", "支付成功");
      // that.gtag("purchase_start", "用户填写完各种卡号信息后 (Pay $10.00)，跳转支付");
    },
  },
};
</script>
<style lang="scss" scoped>
.loading {
  background: url("~@/assets/searching.gif") no-repeat center;
  background-size: contain;
  width: 40px;
  height: 40px;
  margin-left: 5px;
}
</style>
