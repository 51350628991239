import { httpService } from "../common/vue-plugin/httpService";
import { root } from "@/common/config";
export const createOrder = (data) =>
  httpService(
    {
      url: `${root}/order/create`,
      method: "post",
    },
    data,
  );

export const checkoutSession = (data) =>
  httpService(
    {
      url: `${root}/pay/create-checkout-session`,
      method: "get",
      isNeedLoading: true,
      storeKey: "payLoading",
    },
    data,
  );

export const getCoordinatesByIp = (data) =>
  httpService(
    {
      url: `${root}/location/getCoordinatesByIp`,
      method: "get",
      isNeedLoading: false,
    },
    data,
  );
