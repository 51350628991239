<template>
  <div class="lookFor-location text-center pb-9">
    <v-card-text>
      <div class="content-box">
        <v-scroll-x-reverse-transition mode="out-in">
          <template v-for="item in pictureList">
            <div
              :key="item.value"
              v-if="progressValue >= item.value && progressValue < item.limitValue"
            >
              <p class="text-h6 mt-6">{{ item.text }}</p>
              <div :class="`icon-box ${item.className}`"></div>
            </div>
          </template>
        </v-scroll-x-reverse-transition>
        <div class="process-box d-inline-block" v-if="progressValue < 110">
          <div class="text-subtitle-2 mb-3">{{ $t("lang.home.lookFor") }}</div>
          <div class="process">
            <v-progress-linear
              v-model="progressValue"
              background-color="#e4e4f0"
              :color="themeColor"
              rounded
            ></v-progress-linear>
          </div>
        </div>
        <div class="processBtn mt-3" v-if="progressValue >= 110">
          <v-btn
            block
            x-large
            dark
            depressed
            color="#6C5BE7"
            :rounded="true"
            class="text-transform-none"
            :loading="submitLoading"
            @click="$emit('getLocation')"
          >
            {{ $t("lang.home.getLocation") }}
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </div>
</template>
<script>
import { themeColor } from "@/common/const";

export default {
  props: {
    submitLoading: Boolean,
  },
  data() {
    return {
      themeColor,
      progressValue: 0,
      timer: null,
      pictureList: [
        {
          text: this.$t("lang.home.locationWriting"),
          className: "p-locationWriting",
          value: 0,
          limitValue: 30,
        },
        {
          text: this.$t("lang.home.satelliteLookup"),
          className: "p-satelliteLookup",
          value: 30,
          limitValue: 60,
        },
        {
          text: this.$t("lang.home.mapGenerating"),
          className: "p-mapGenerating",
          value: 60,
          limitValue: 90,
        },
        {
          text: this.$t("lang.home.deviceLocated"),
          className: "p-deviceLocated",
          value: 90,
          limitValue: 1000,
        },
      ],
    };
  },
  created() {
    this.changeProgressValue();
  },
  destroyed() {
    this.timer && window.clearTimeout(this.timer);
  },
  methods: {
    changeProgressValue() {
      this.timer = setTimeout(() => {
        this.progressValue = this.progressValue + 2.5;
        if (90 <= this.progressValue && this.progressValue <= 92.5) {
          setTimeout(() => {
            this.$emit("changeLocationByIp");
          }, 1000);
        }
        if (this.progressValue < 100) {
          this.changeProgressValue();
        } else {
          setTimeout(() => {
            this.$emit("cancelChangeLocationByIp");
            this.progressValue = 110;
          }, 2500);
          window.clearTimeout(this.timer);
        }
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
.process-box {
  width: 50%;
}
.icon-box {
  width: 100px;
  height: 100px;
  margin: 10px 0 30px;
  display: inline-block;
}
.p-locationWriting {
  background: url("~@/assets/lookIcon/writing.png") no-repeat center center;
  background-size: contain;
}
.p-satelliteLookup {
  background: url("~@/assets/lookIcon/lookup.png") no-repeat center center;
  background-size: contain;
}
.p-mapGenerating {
  background: url("~@/assets/lookIcon/map.png") no-repeat center center;
  background-size: contain;
}
.p-deviceLocated {
  background: url("~@/assets/lookIcon/end.png") no-repeat center center;
  background-size: contain;
}
</style>
